import { Injectable } from '@angular/core';
import emailjs, { EmailJSResponseStatus } from 'emailjs-com';
@Injectable({
  providedIn: 'root'
})
export class EmailService {

constructor() { }
sendEmail(templateParams: any): Promise<EmailJSResponseStatus> {
  return emailjs.send('service_sofrq1f', 'template_05m8voe', templateParams, 'mPIwCXYnAGuRASvAj')
    .then((response: EmailJSResponseStatus) => {
      console.log('Email sent successfully:', response);
      return response;
    }, (error) => {
      console.error('Email sent error:', error);
      throw error;
    });
}
}


